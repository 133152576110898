import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-edit-staff',
  templateUrl: './edit-staff.component.html',
  styleUrls: ['./edit-staff.component.css']
})
export class EditStaffComponent implements OnInit {
  editStaffForm: FormGroup;
  id: any;
  staffDetails: any;
  ipAddress: any;
  staffPrivileges: any = [];
  newArr: any = [];
  checkedBoolead: boolean = true;

  constructor(public router: Router, public param: ActivatedRoute, public service: ServiceService, public http: HttpClient) {
    this.param.queryParams.subscribe((res) => {
      this.id = res.id;
    });
    this.http.get<{ ip: string }>('https://jsonip.com')
      .subscribe(data => {
        this.ipAddress = data
      })
  }


  ngOnInit() {
    this.editStaffForm = new FormGroup({
      firstName: new FormControl('', [Validators.pattern(/^[a-z,.'-]+$/i), Validators.required]),
      lastName: new FormControl('', [Validators.pattern(/^[a-z,.'-]+$/i), Validators.required]),
      email: new FormControl('', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      gender: new FormControl('', Validators.required),
      role: new FormControl('', Validators.required),
      phoneNumber: new FormControl('', [Validators.required, Validators.pattern(/^[1-9][0-9]{8,13}$/), Validators.maxLength(18)]),
      dashboardChecked: new FormControl(''),
      staffChecked: new FormControl(''),
      hotWalletChecked: new FormControl(''),
      kycChecked: new FormControl(''),
      walletChecked: new FormControl(''),
      staticChecked: new FormControl(''),
      logsChecked: new FormControl(''),
      ticketChecked: new FormControl(''),
      tradeChecked: new FormControl(''),
      disputeChecked: new FormControl(''),
      bankChecked: new FormControl(''),
      feeChecked: new FormControl('')
    })
    this.getProfile();
  }

  getProfile() {
    this.service.showSpinner();
    let dataa = {
      "primaryIdCommonPerRequest": this.id
    }
    this.service.post('account/admin/user-management/get-staff-user-profile', dataa).subscribe((res) => {
      this.staffDetails = res['data']['staffDetails'];
      this.staffPrivileges = res['data']['staffPrivileges'];
      this.staffPrivileges.forEach(element => {

        if (element == 'DASHBOARD') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            dashboardChecked: this.checkedBoolead,
          })
        }
        else if (element == 'HOT_COLD_LIMIT_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            hotWalletChecked: this.checkedBoolead,
          })
        }
        else if (element == 'KYC_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            kycChecked: this.checkedBoolead,
          })
        }
        else if (element == 'WALLET_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            walletChecked: this.checkedBoolead,
          })
        }
        else if (element == 'STATIC_CONTENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            staticChecked: this.checkedBoolead,
          })
        }
        else if (element == 'LOGS_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            logsChecked: this.checkedBoolead,
          })
        }
        else if (element == 'TICKET_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            ticketChecked: this.checkedBoolead,
          })
        }
        else if (element == 'TRADE_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            tradeChecked: this.checkedBoolead,
          })
        }
        else if (element == 'DISPUTE_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            disputeChecked: this.checkedBoolead,
          })
        }
        else if (element == 'STAFF_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            staffChecked: this.checkedBoolead,
          })
        }
      });
      this.editStaffForm.patchValue({
        firstName: this.staffDetails.firstName,
        lastName: this.staffDetails.lastName,
        email: this.staffDetails.email,
        gender: this.staffDetails.gender,
        role: this.staffDetails.role,
        phoneNumber: this.staffDetails.phoneNo
      })
      setTimeout(() => {
        this.service.hideSpinner();
      }, 2000)

    })
  }

  editStaff() {
    let data = {
      "address": "string",
      "city": "string",
      "country": "INDIA",
      "countryCode": "+91",
      "dob": "string",
      "email": this.editStaffForm.value.email,
      "firstName": this.editStaffForm.value.firstName,
      "gender": this.editStaffForm.value.gender,
      "imageUrl": "string",
      "ipAddress": this.ipAddress.ip,
      "lastName": this.editStaffForm.value.lastName,
      "location": "string",
      "phoneNo": this.editStaffForm.value.phoneNumber,
      "previlage": this.newArr,
      "roleStatus": "SUBADMIN",
      "state": "string",
      "webUrl": "string",
      "userIdToUpdate": this.id
    }
    this.service.showSpinner()
    this.service.post('account/admin/user-management/edit-sub-admin', data).subscribe((res) => {
      this.service.hideSpinner()
      if(res['status'] == 200){
        this.service.toasterSucc(res['message'])
        this.router.navigate(['/staff-management'])
      }
    })
  }

  /** Function for checkbox click */
  checkboxClick(value, checked) {
    if (checked == true) {
      this.newArr.push(value);
    } else {
      let index = this.newArr.findIndex(x => x == value)
      this.newArr.splice(index, 1)
    }
  }

}
