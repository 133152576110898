import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from '../service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
declare var $:any
@Component({
  selector: 'app-wallet-management',
  templateUrl: './wallet-management.component.html',
  styleUrls: ['./wallet-management.component.css']
})
export class WalletManagementComponent implements OnInit {
  selected: string = "btc";
  coinlist: any=[];
  coinListArr: any=[];
  transferForm: FormGroup;
  loopData: any=[];
  lengthTotal: any;
  minDate: any;
  itemsPerPage = 10;
  currentPage:number = 1;
  totalItems: any
  flip : string = "List";
  constructor(
    private router : Router,
    public service: ServiceService
  ) { }

  ngOnInit() {
    this.checkTransferFormValidations();
    // this.sidemenu();
    this.getCoinWalletList();
  }
  copyToClipboard(item) {
        
      this.service.toasterSucc('Copied')
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }
  pagination(page) {
    
    this.currentPage = page
    if(this.flip == "searchCoin"){
      this.searchcoin();
    }else if(this.flip == "searchCoinn"){
      this.searchcoinn();
    }
    else{
      this.getAllTransactionHistory()
    }
    
  }
  checkTransferFormValidations() {
    this.transferForm = new FormGroup({
        'currency': new FormControl(''),
        'type': new FormControl(''),
        'fromDate' : new FormControl(''),
        'toDate' : new FormControl('')
    })
}

  getCoinWalletList() {

    this.service.showSpinner();
   // this.coinlist = [];
    this.service.get('wallet/coin/get-coin-list').subscribe((res: any) => {
      this.service.hideSpinner()
        if (res.status == 200) {
          this.getAllTransactionHistory();
            this.coinlist = res.data;
            this.coinlist.forEach(element => {
                this.coinListArr.push(element.coinShortName);
            });
        }
        else {
            this.service.hideSpinner();
            this.getAllTransactionHistory();
        }
    },(error)=>{
        this.service.hideSpinner();
        if(error['status']==401){
          this.service.onLogout();
          this.service.toasterErr('Unauthorized Access');
      }
      else{
          this.service.toasterErr('Something Went Wrong');
      }
    })
}
  
  // sidemenu() {
  //   $(".btn-toggle,.close_panel").click(function() {
  //     $("body").toggleClass("toggle-wrapper");
  // });
  // }

  // to check tab
  selectTab(path) {
    
    this.selected = path
   
    if (path == 'btc') {
     
    } else if (path == 'eth') {

    } else if (path == 'xrp') {
     
    } else if (path == 'ltc') {

    } 
  }

  getBTC(){
    
  }

  getAllTransactionHistory() {
    this.service.showSpinner()
    // this.loopData = []
    // this.lengthTotal = 0;
    this.service.get(`wallet/admin/transaction-history/get-all-transaction-history?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}`).subscribe((res:any)=>{
      this.service.hideSpinner()
      this.loopData = res.data.resultlist;
      this.lengthTotal = res['data'].totalCount

    },err =>{
      this.service.hideSpinner();
      if(err.status >= 400){
        this.service.toasterErr(err.message)
      }
    })

  }

  searchcoinn() {
    this.service.showSpinner()
    this.flip = "searchCoinn"
    this.service.get('wallet/admin/transaction-history/get-all-transaction-history?coinName='+(this.transferForm.value.currency)+'&page='+(this.currentPage - 1)+'&pageSize='+(this.itemsPerPage)+'&txnType='+(this.transferForm.value.type)).subscribe((res:any)=>{
      this.service.hideSpinner()
      this.loopData = res.data.resultlist;
      this.lengthTotal = res.data.totalCount;
    })
  }

  searchcoin() {
    this.service.showSpinner()
    this.flip = "searchCoin"
    
    this.service.get('wallet/admin/transaction-history/get-all-transaction-history?coinName='+(this.transferForm.value.currency)+'&page='+(this.currentPage - 1)+'&pageSize='+(this.itemsPerPage)).subscribe((res:any)=>{
      this.service.hideSpinner()
      this.loopData = res.data.resultlist;
      this.lengthTotal = res.data.totalCount;
    })
  }

  searchText() {
    this.service.showSpinner()
    this.loopData = []
    this.lengthTotal = 0
    
    this.minDate = this.transferForm.value.fromDate;
    // wallet/admin/transaction-history/get-all-transaction-history?fromDate=1&page=0&pageSize=10&toDate=1
    this.service.get('wallet/admin/transaction-history/get-all-transaction-history?fromDate='+(Math.round(new Date(this.transferForm.value.fromDate).getTime()))+'&toDate='+(Math.round(new Date(this.transferForm.value.toDate).getTime()))+ '&page='+(this.currentPage - 1)+'&pageSize='+(this.itemsPerPage)).subscribe((res:any)=>{
      this.service.hideSpinner()
      this.loopData = res.data.resultlist;
      this.lengthTotal = res.data.totalCount;
    })
  }

  reset() {
    this.flip = 'List'
    this.transferForm.reset();
    this.getCoinWalletList();
    this.transferForm.get('type').setValue('Select')
    
  }
}
